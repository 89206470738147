import { BrandName, BrandName_Generic_UNSAFE, BurgerKing_Generic, NormalizedBrandName } from '../enums';

export function isBkFR(brandName: BrandName): boolean {
	return brandName === BrandName.BurgerKing_France;
}

export function isBkRE(brandName: BrandName): boolean {
	return brandName === BrandName.BurgerKing_Reunion;
}

export function isBk(brandName: BrandName | BrandName_Generic_UNSAFE): boolean {
	return isBkFR(brandName as BrandName) || isBkRE(brandName as BrandName) || brandName === BurgerKing_Generic;
}

export function isCMRE(brandName: BrandName): boolean {
	return brandName === BrandName.CapMerchant_Reunion;
}

/**
 * Because legacy Apps require just "bk" instead of "bk_fr" or "bk_re".
 * That "bk" is used in AngularJS directive feature-flag="bk".
 */
export function getNormalizedBrandName(brandName: BrandName): NormalizedBrandName {
	return isBk(brandName) ? BurgerKing_Generic : brandName;
}

export function isCOFR(brandName: BrandName): boolean {
	return brandName === BrandName.Cora_France;
}

export function isQuick(brandName: BrandName): boolean {
	return brandName === BrandName.Quick;
}

export function isPMFR(brandName: BrandName): boolean {
	return brandName === BrandName.PokeMama_France;
}

export function isBCFR(brandName: BrandName): boolean {
	return brandName === BrandName.BChef_France;
}
